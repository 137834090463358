import { Form, SubmissionError, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import OrganizationsUsersList from '../../../../components/base/Organizations/Users/List';
import organizationData from '../../../../objects/organizations/datas.json';
import updateMutation from '../../../../objects/organizations/mutations/update.graphql';
import { organizationsRetrieve as retrieveQuery } from '../../../../objects/organizations/queries';
import OrganizationType from '../../../../types/Organization';
import requiredAuth from '../../../../utils/requireAuth';

const OrganizationsUpdatePage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'place';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateMutationFunc] = useMutation(updateMutation);
  const { data, loading, refetch } = useQuery(retrieveQuery, {
    variables: {
      id,
    },
  });

  const organization: OrganizationType =
    data && (data.organizationsRetrieve as OrganizationType);

  if (!organization) {
    return <CircularProgress fullPage />;
  }

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    let result;
    try {
      result = await updateMutationFunc({
        variables: {
          id,
          data: {
            ...values,
          },
        },
      });
    } catch (error) {
      throw new SubmissionError({
        _error: error instanceof Error ? error.message : error,
      });
    }

    if (result.errors) {
      const error = result?.errors?.map(({ message }) => message).join(',');
      throw new SubmissionError({
        _error: error,
      });
    }

    refetch();
  };

  return (
    <GridSplit
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              size="small"
              status="secondary"
              to={`/admin/administrators/create/${organization.id}/`}
            >
              {t('admin.organizations.create-admin.label')}
            </Button>
            <Button onClick={handleSaveOnClick} size="small">
              Enregistrer
            </Button>
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: organization.name,
      }}
    >
      {loading && (
        <div>L&apos;administrateur est en cours de rafraichissement</div>
      )}
      <Form
        className="mx-6 mb-6"
        datas={organizationData}
        hideSubmitButton
        initialValues={organization}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: 'Enregistrer',
          size: 'small',
        }}
      />
      <OrganizationsUsersList organizationId={id} />
    </GridSplit>
  );
};

export default requiredAuth(OrganizationsUpdatePage, {
  admin: true,
  layout: true,
});
