import React, { FC } from 'react';

import administratorsQuery from '../../../../../objects/administrators/queries';
import query from '../../../../../utils/query';
import Items from '../../../../generic/Items';

interface OrganizationsUsersListProps {
  organizationId: string;
}

const Administrators = query(Items, administratorsQuery);

const OrganizationsUsersList: FC<OrganizationsUsersListProps> = ({
  organizationId,
}) => (
  <div className="mt-12 mx-6 bg-light-50 p-6 rounded">
    <Administrators
      fields={[
        {
          className: 'flex-1',
          label: 'Nom',
          name: 'lastName',
        },
        {
          className: 'flex-1',
          label: 'Prénom',
          name: 'firstName',
        },

        {
          className: 'flex-1',
          label: 'user.list.field.createdAt',
          name: 'createdAt',
          type: 'date',
          typeFormat: 'DD/MM/YY',
        },

        {
          className: 'flex-1',
          label: 'user.list.field.email',
          name: 'email',
        },
      ]}
      filter={{
        organizationId: {
          eq: organizationId,
        },
      }}
      itemActions={[]}
      pathname="/admin/administrators/"
    />
  </div>
);

export default OrganizationsUsersList;
